<template>
  <div>
    <div class="live">
      <div class="live-top d-flex align-center justify-center">
        <img src="../../assets/img/live_b.png" alt="" />
        直播课程
      </div>
      <div class="live-title">
        {{ name }}
      </div>
    </div>
    <div class="start">
      <div class="head">
        <div class="title d-flex align-center">直播目录</div>
      </div>
      <ul class="list" v-if="coursesList">
        <li
          class="item d-flex align-center"
          v-for="(items, idx) in coursesList"
          :key="idx"
        >
          <div class="order">
            <img src="../../assets/img/live_head.png" alt="" />
          </div>
          <div class="detail d-flex align-center justify-between">
            <div class="detail-title">
              {{ items.name }}
            </div>
            <div class="detail-time" v-if="items.lessonStatus == 2">
              {{ items.startDate }}-{{ items.invalidHm }}
            </div>
            <div
              class="status d-flex align-center"
              v-if="items.lessonStatus == 0 || items.lessonStatus == 1"
            >
              直播中
              <img
                class="status-img"
                src="../../assets/img/live_z.png"
                alt=""
              />
            </div>
            <!-- <a
              class="detail-dow d-flex align-center cursor"
              v-if="items.lessonStatus == -1 && items.docList[0]"
              :href="items.docList[0].fileurl"
              :download="items.docList[0].name"
            >
              <img src="../../assets/img/live_dow.png" alt="" /> 资料下载
            </a> -->
            <!-- <div
              class="detail-dowb d-flex align-center cursor"
              v-if="items.lessonStatus == -1"
            >
              <img src="../../assets/img/live_dowb.png" alt="" /> 资料下载
            </div> -->
            <!-- <div v-if="items.documentsList.length != 0">
              <a
                class="detail-dowb d-flex align-center cursor"
                :href="url +'/'+ items.documentsList[0].fileurl"
              >
              <img src="../../assets/img/live_dowb.png" alt="" />
                资料下载
              </a>
            </div> -->
            <div v-if="items.documentsList.length != 0">
              <div
                class="detail-dowb d-flex align-center cursor"
                @click="goDatalist(items)"
              >
                <img src="../../assets/img/live_dowb.png" alt="" />
                资料下载
              </div>
            </div>

            <div
              v-if="items.lessonStatus == -1"
              class="detail-btn d-flex align-center justify-center cursor"
              @click="goRecording(items)"
            >
              查看回放
            </div>
            <div
              v-if="items.lessonStatus == 0 || items.lessonStatus == 1"
              class="detail-btnc d-flex align-center justify-center cursor"
              @click="goLive(items)"
            >
              观看直播
            </div>
            <div
              v-if="items.lessonStatus == 2"
              class="detail-btnb d-flex align-center justify-center cursor"
            >
              即将开播
            </div>
          </div>
        </li>
      </ul>
      <div
        v-if="!coursesList"
        class="kong d-flex align-center flex-column justify-center"
      >
        <img class="kong-img" src="../../assets/img/kong.png" alt="" />
        <div class="kong-txt">暂无内容</div>
      </div>
      <v-pagination
        v-show="total > 13"
        :total="total"
        :current-page="current"
        :display="display"
        @pagechange="pagechange"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/paginationUser";
import config from "../../utils/config";
import { type } from "os";
export default {
  data() {
    return {
      total: 0, // 记录总条数
      display: 13, // 每页显示条数
      current: 1, // 当前的页数
      liveList: "",
      coursesList: "",
      tabList: "",
      tabIndex: 0,
      id: this.$route.query.id,
      name: "",
      rightNavShow: true,
      url: config.baseURL
    };
  },
  components: {
    "v-pagination": pagination
  },
  mounted() {
    this.getCourseslist();
    this.isMobile();
  },
  methods: {
    goDatalist(item) {
      console.log(item)
      window.localStorage.setItem("documentsList", JSON.stringify(item.documentsList));
      this.$router.push({
        name: `dataList`,
      })
    },
    goLive(item) {
      if (item.scene == 1) {
        const user = JSON.parse(window.localStorage.getItem("user"));
        let redirect_url =
          item.studentJoinUrl +
          "?token=" +
          item.studentClientToken +
          "&nickname=" +
          user.rname +
          "&sec=plain&type=jsonp";
        location.href = redirect_url;
      } else {
        location.href = "./html/live.html";
        let vodlive = {
          ownerid: item.roomId,
          authcode: item.studentToken,
          url: config.baseURL,
          courseId: item.id,
          name: item.name,
        };
        window.localStorage.setItem("vodlive", JSON.stringify(vodlive));
      }
      // this.$router.push("recording");
    },
    goRecording(item) {
      console.log(item);
      if (this.rightNavShow) {
        location.href = "./html/vod.html";
        let vodlive = {
          ownerid: item.courseWareId,
          authcode: item.backToken,
          url: config.baseURL,
          courseId: item.backId,
          name: item.name,
          type: 2
        };
        window.localStorage.setItem("vodlive", JSON.stringify(vodlive));
      } else {
        location.href = "./html/mobile_vod_bar.html";
        let vodlive = {
          ownerid: item.courseWareId,
          authcode: item.backToken,
          url: config.baseURL,
          courseId: item.backId,
          name: item.name,
          type: 2
        };
        window.localStorage.setItem("vodlive", JSON.stringify(vodlive));
      }

      // this.$router.push("recording");
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      console.log(flag);
      if (flag === null) {
        console.log("pc端");
        this.rightNavShow = true;
      } else {
        console.log("移动端");
        this.rightNavShow = false;
      }
    },
    pagechange(currentPage) {
      console.log(currentPage); //该参数就是当前点击的页码数
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
      this.current = currentPage;
      this.getAllMajor();
      const currentY =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(currentY, 0);
    },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    },
    //录播课包
    async getCourseslist(params) {
      let _this = this;
      let data = {
        pageNum: _this.current,
        pageSize: _this.display,
        liveId: _this.id
      }
      const res = await this.$ajaxRequest('post', 'myLiveLessons', data)
      if (res.code == 200) {
        _this.coursesList = res.data.list;
        _this.total = res.data.totalRows;
        _this.name = res.data.list[0].name;
      }

    },
    // getCourseslist() {
    //   let _this = this;
    //   _this.$api
    //     .myLiveLessons({
    //       start: _this.current - 1,
    //       limit: _this.display,
    //       courseId: _this.id
    //     })
    //     .then(res => {
    //       console.log(res);
    //       _this.coursesList = res.data;
    //       _this.total = res.count;
    //       // this.tabList = res.data;
    //       _this.name = res.data[0].course.name;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    // downloadDoc(item, index) {
    //   // console.log(item.docList[0].)
    //   // let _this = this;
    //   // _this.$api
    //   //   .downloadServlet({
    //   //     filepath: item.docList[0].fileurl
    //   //   })
    //   //   .then(res => {
    //   //     console.log(res);
    //   //     // this.tabList = res.data;
    //   //   })
    //   //   .catch(err => {
    //   //     console.log(err);
    //   //   });
    // },
    // downloadDoc(item) {
    //   let url = window.URL.createObjectURL(new Blob([item.docList[0].fileurl])); //创建下载链接
    //   let link = document.createElement("a");
    //   link.style.display = "none";
    //   link.href = url;
    //   link.setAttribute("download");
    //   document.body.appendChild(link);
    //   link.click();
    // },
    selectTab(id) {
      if (this.tabIndex != id) {
        this.tabIndex = id;
        this.getCourseslist();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
.tab_active {
  border-color: #5f2eff;
  background: #5f2eff;
  color: #ffffff !important;
}
.tabs_active {
  border-color: #5f2eff !important;
}
.live {
  width: 1000px;
  height: 140px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  position: relative;
  padding: 66px 40px 0 40px;
  box-sizing: border-box;
  .live-top {
    width: 116px;
    height: 36px;
    background: #ffeccd;
    border-radius: 0px 0px 10px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #ff8707;
    position: absolute;
    top: 0;
    left: 0;
    img {
      width: 22px;
      height: 14px;
      margin-right: 7px;
    }
  }
  .live-title {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .live-list {
    margin-top: 20px;
    .live-item {
      margin-right: 30px;
      .live-img {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        overflow: hidden;
        background-color: skyblue;
      }
      .live-name {
        font-size: 14px;
        color: #666666;
        margin-top: 7px;
      }
    }
  }
}
.start {
  width: 1000px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  padding: 33px 36px 36px 36px;
  box-sizing: border-box;
  margin-top: 20px;
  .head {
    width: 100%;
    box-sizing: border-box;
    .title {
      font-size: 20px;
      color: #333333;
    }
  }
  .list {
    .item {
      height: 45px;
      .order {
        width: 16px;
        height: 16px;
        position: relative;
        margin-left: 16px;
        margin-right: 30px;
        img {
          position: relative;
        }
        &::before {
          content: "";
          display: block;
          height: 45px;
          width: 2px;
          background: #f4f0ff;
          position: absolute;
          top: 8px;
          left: 7px;
        }
      }
      .detail {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        .detail-title {
          width: 319px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          color: #333333;
        }
        .detail-time {
          font-size: 14px;
          color: #999999;
          margin-left: 40px;
        }
        .status {
          font-size: 14px;
          color: #f53444;
          margin-left: 40px;
          .status-img {
            width: 20px;
            height: 14px;
            margin-left: 14px;
          }
        }

        .detail-btn {
          width: 80px;
          height: 22px;
          border: 1px solid #f8a927;
          border-radius: 11px;
          // margin-left: auto;
          font-size: 12px;
          color: #f8a927;
        }
        .detail-btnc {
          width: 80px;
          height: 22px;
          background-image: linear-gradient(176deg, #406bff 0%, #5f2eff 100%);
          border-radius: 11px;
          // margin-left: auto;
          font-size: 12px;
          color: #ffffff;
        }
        .detail-btnb {
          width: 80px;
          height: 22px;
          border: 1px solid #bfc3ce;
          border-radius: 11px;
          // margin-left: auto;
          font-size: 12px;
          color: #bfc3ce;
        }
        .detail-dow {
          font-size: 14px;
          color: #9879ff;
          margin-left: auto;
          margin-right: 61px;
          img {
            height: 17px;
            width: 17px;
          }
        }
        .detail-dowb {
          font-size: 14px;
          color: #999999;
          margin-left: auto;
          margin-right: 61px;
          img {
            height: 17px;
            width: 17px;
          }
        }
      }
    }
    :last-child {
      .order::before {
        display: none;
      }
    }
  }
}
</style>
